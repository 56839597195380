.print-source {
    display: none;
}

.print-margin{
  padding:0px;
  margin-left: 0px;
    margin-top: 0px;
    margin-right: 0px
}

.print-no {
    display: block !important
}


.report-dom tr:nth-child(even) {
  background: #ffffff;
}

text{
  font-size: 12px  !important;
  margin: 0  !important;
  padding: 0 !important
}
.report-dom tr:nth-child(odd) {
  background: #fbfbfb;
}
  
  /* body > .print-source {
    display: block !important
  } */
  
  @media print {

    .print-margin{
      padding:5px !important;
      margin-left: 96px !important;
        margin-top: 72px !important;
        margin-right: 72px !important
    }

    .print-no {
        display: none !important
    }

    .print-source {
      display: block;
      /* background-color: green; */
    }
  }